import { render, staticRenderFns } from "./UpdatePop.vue?vue&type=template&id=b2ed96fa&scoped=true"
import script from "./UpdatePop.vue?vue&type=script&lang=js"
export * from "./UpdatePop.vue?vue&type=script&lang=js"
import style0 from "./UpdatePop.vue?vue&type=style&index=0&id=b2ed96fa&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2ed96fa",
  null
  
)

export default component.exports