<template>
  <div class="content_foot">
    <div class="font_logo fcenter">
      <img :src="logo" />
      <!-- <img :src="require('@/assets/imgs/bottom_logo.png')" /> -->
    </div>
    <div class="foot2">
      <span>AI创作助手 | 让创作更高效 </span>
    </div>
    <div class="foot3">
      <p>
        <!-- 是一个基于AIGC技术和大语言模型开发的广告营销内容创作的智能助力平台，综合了自然语言处理、深度学习、内容定制创作专业性技术等，提供专业内容创作服务助力广告内容从业者们更低成本，更高效率的进行创作，希望每位创作者可以轻松拥有自己的智能创作助手。 -->
        {{appName}}，一款基于大语言模型、混合大模型和AIGC技术研发的内容创作智能助手，综合了自然语言处理、深度学习、内容创作专业性数据和生成逻辑等，助力内容创作者们轻松拥有自己的AI创作助手，更低成本，更高效率的进行创作。
      </p>
    </div>
    <div class="foot4">
      联系我们： {{appEmail}}
      <span class="foottm"
        >Copyright©{{appName}}
        <span class="miniTM">TM</span>
      </span>
    </div>
  </div>
</template>
<script>
import mixinCommon from '@/mixin/common'
export default {
  mixins:[mixinCommon],
  data() {
    return {
	    appName: this.$store.state.setApplicationInfo.title,
	    appEmail: this.$store.state.setApplicationInfo.email
    }
  },
  created(){
    this.getLogo('3')
  },
  methods: {
  },
}
</script>

<style lang="less" scoped>
.content_foot {
  box-sizing: border-box;
  max-width: @cw;
  width: 100%;
  margin: 0 auto;
  .font_logo {
    margin-top: 50px;
    img {
      width: 80px;
    }
  }
  .foot2 {
    font-size: 16px;
    color: #3D3D3D ;
    text-align: center;
    margin-top: 10px;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
  }
  .foot3 {
    margin-top: 10px;
    font-size: 12px;
    color: #b2b2b2;
    text-indent: 2em;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
  }
  .foot4 {
    font-size: 14px;
    color: #b2b2b2;
    text-align: center;
    margin-top: 40px;
    padding-bottom: 30px;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    .foottm {
      position: relative;
      margin-left: 10px;
      .miniTM {
        position: absolute;
        z-index: 1;
        right: -17px;
        top: -5px;
        font-size: 10px;
        transform: scale(0.5);
      }
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 992px) {
  .content_foot {
    box-sizing: border-box;
    max-width: @cw;
    width: 100%;
    margin: 0 auto;
    .font_logo {
      margin-top: 50px;
      img {
        width: 80px;
      }
    }
    .foot2 {
      font-size: 16px;
      color: @f;
      text-align: center;
      margin-top: 10px;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;
    }
    .foot3 {
      margin-top: 10px;
      font-size: 12px;
      color: #b2b2b2;
      text-indent: 2em;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;
    }
    .foot4 {
      font-size: 12px;
      color: #b2b2b2;
      text-align: center;
      margin-top: 30px;
      padding-bottom: 30px;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;
      .foottm {
        position: relative;
        margin-left: 5px !important;
        .miniTM {
          position: absolute;
          z-index: 1;
          right: -13px;
          top: -5px;
          font-size: 10px;
          transform: scale(0.5);
        }
      }
    }
  }
}
</style>

