<template>
  <div class="dialog-feedback">
     <van-popup
      v-model="diaValue"
      class="pup rebatepup"
      round
      :close-on-click-overlay="true"
      :closeable="true"
      @click-overlay="close"
      @click-close-icon="close"
      :style="{ height: '540px', width: '880px' }"
    >
    <p class="title">问题反馈</p>
    <div class="content">
      <el-form ref="form" :model="feedBackObj" label-width="80px"
      label-position="left"  :rules="rules">
        <el-form-item label="问题类型" :required="true" prop="feedback_type">
          <el-select v-model="feedBackObj.feedback_type" placeholder="请选择问题类型">
            <el-option
              v-for="item in questionTypeList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="问题描述" :required="true" prop="feedback_desc">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入问题描述"
            v-model="feedBackObj.feedback_desc">
          </el-input>
        </el-form-item>
        <el-form-item label="上传截图" :required="false">
          <el-upload
            class="avatar-uploader"
            action="#"
            :show-file-list="false"
            :drag="false"
            accept=".png,.jpg"
            :http-request="uploadRequest"
            :before-upload="beforeAvatarUpload">
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button @click="close">取消</el-button>
          <el-button type="primary" @click="onSubmit">确定</el-button>
        </el-form-item>
      </el-form>
    </div>
    </van-popup>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: {
    value: {
      default: false,
    },
  },
  data () {
    return {
      feedBackObj: {},
      questionTypeList: [],
      imageUrl: '',
      save_path: '',
      rules: {
          feedback_type: [
            { required: true, message: '请选择问题类型', trigger: 'change' },
          ],
          feedback_desc: [
            { required: true, message: '请输入问题描述', trigger: 'blur' }
          ],
        }
    }
  },
  computed: {
    ...mapState({
      useInfo: (state) => state.useInfo,
    }),
    diaValue: {
      get() {
        return this.value
      },
      set() {},
    },
  },
  created () {
    this.getList()
  },
  methods: {
    onSubmit () {
      this.$refs.form.validate((valid) => {
        console.log(valid, 'validvalidvalid')
        if (valid){
          this.$request('/feedback/create', {
              feedback_type: this.feedBackObj.feedback_type,
              feedback_desc: this.feedBackObj.feedback_desc,
              feedback_url: this.save_path,
              member_token: this.useInfo && this.useInfo.member_token,
            }).then((rs) => {
              if (rs && rs.code === 200) {
                this.$message({
                  message: '提交成功',
                  type: 'success',
                })
                this.close()
              } else {
                // console.log(rs.message)
              }
            })
        }
      })
    },
    getList() {
      this.$request('/feedback/get-type', {
        member_token: this.useInfo && this.useInfo.member_token,
      }, 'get').then((rs) => {
        if (rs && rs.code === 200) {
          if (rs.data) {
            this.questionTypeList = rs.data
          }
        } else {
          // console.log(rs.message)
        }
      })
    },
    beforeAvatarUpload(file) {
      return true
        // const isJPG = file.type === 'image/jpeg';
        // const isLt2M = file.size / 1024 / 1024 < 2;

        // if (!isJPG) {
        //   this.$message.error('上传头像图片只能是 JPG 格式!');
        // }
        // if (!isLt2M) {
        //   this.$message.error('上传头像图片大小不能超过 2MB!');
        // }
        // return isJPG && isLt2M;
    },
    uploadRequest(file) {
      console.log(file.file, 'file')
      this.$request('/upload/img', {
        file: file.file,
        type:'feedback',
        member_token: this.useInfo && this.useInfo.member_token,
      },'post').then((rs) => {
        this.uploading = false
        if (rs && rs.code === 200) {
          // proxy_url // 显示使用
          this.save_path = rs.data.save_path
          // upload_url // 显示使用
          this.imageUrl = rs.data.image_url
        }
      })
    },
    afterRead (file){
       if (file.size && file.size / 1024 / 1024 > 10) {
        this.$toast('最大为10M，请检查上传内容的大小。')
        return false
      }
      this.uploading = true
      this.$request('/upload/img', {
        file: file.file,
        member_token: this.useInfo && this.useInfo.member_token,
      },'post').then((rs) => {
        this.uploading = false
        if (rs && rs.code === 200) {
          // proxy_url // 显示使用
          this.uploadViewUrl = rs.data.proxy_url
          // upload_url // 生图入参
          this.imageUrl = rs.data.image_url
        }
      })
    },
    close() {
      this.$emit('input', false)
    },
  }
}
</script>
<style lang="less" scoped>
.dialog-feedback{
  /deep/ .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .rebatepup{
    .title {
    font-size: 20px;
    width: 92%;
    text-align: center;
    color: #3d3d3d;
    font-weight: bold;
    background-color: #fff;
    height: 60px;
    display: flex;
    align-items: center;
    // justify-content: center;
    border-bottom: 1px solid #d8d8d8;
    margin: 0 20px;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 20px;
    padding-top: 20px;
  }
  }
  .content{
    margin: 0 40px;
    margin-top: 90px;
    // height: 480px;
    overflow: auto;
    .rate{
      border-bottom: 1px solid #D8D8D8;
      display: flex;
      justify-content: flex-end;
      color: #999;
      font-size: 14px;
      padding-bottom: 6px;
      margin-bottom: 10px;
    }
    .table_wrap{
      min-height: 320px;
     /deep/ .el-table{
        min-height: 320px;
      }
    }
    /deep/ .el-table {
      .Bgray{
        background-color: #F3F3F3!important;
      }
    }
    .tip{
      color: #999;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      i{
        font-size: 16px;
      }
      .tip_right{
        .money{
          margin-right: 6px;
        }
        .Cred{
          color: #FD3A3A;
        }
      }
    }
    .bottom{
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p{
        color: #517DFD;
        font-size: 14px;
        display: flex;
      align-items: center;
      }
      img{
        width: 18px;
      }
    }
  }
  .el-tabs {
    font-size: 16px;
   /deep/ .el-tabs__nav-wrap::after{
    background-color: transparent;
    }
    /deep/ .el-tabs__header{
      padding-bottom: 10px;
      margin-bottom: 0px;
    }
    /deep/.el-tabs__item.is-active{
      color: #517DFD;
    }
    /deep/ .el-tabs__active-bar{
      color:#517DFD;
      background-color: #517DFD;
    }
    /deep/ .el-tabs__item{
      font-size: 18px;
    }
  }
}
.rulePop{
  .title {
    font-size: 20px;
    width: 92%;
    text-align: center;
    color: #3d3d3d;
    font-weight: bold;
    background-color: #fff;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #d8d8d8;
    margin: 0 20px;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 20px;
  }
  .content{
    margin-top: 80px;
    height: 380px;
    overflow: auto;
    box-sizing: border-box;
    p{
      margin-bottom: 10px;
      font-size: 16px;
      margin-left: 16px;
      span{
        font-size: 30px;
        margin: 0 4px;
        vertical-align: sub;
      }
    }
    .special{
      margin-left: 0;
    }
  }
}
.cashPop{
  font-size: 18px;
  .content{
    p{
      span{
      font-size: 18px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      }
      .Cred{
          color: #FD3A3A;
        }
    }
  }
}
</style>