<template>
  <div class="agreement-service">
    <p>生效日期：2023年07月30日</p>
    <h3>导言</h3>
    <p class="indent">我们深知个人信息安全对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最小必要性原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。</p>
     <p class="indent">我们将尽力采用通俗易懂的文字表述向您进行说明和告知，并就本政策中可能与您的合法利益存在重大关系的条款，采用加粗、下划线等形式以提请您的注意。我们强烈建议您在注册账户、使用我们的服务前，仔细阅读并完全理解本政策中的全部内容，并自主进行适当的选择。如果您不同意本政策的内容，我们将无法为您提供完整的产品和服务，同时您也需要立即停止使用{{appName}}。</p>
     <p class="indent">如果您开始使用我们的产品或服务，则视为您已经充分、完全地阅读并理解了本政策中的全部内容，并且同意我们按照本政策的约定收集、使用、存储您的个人信息，以便您享受优质、便捷的服务，同时更好地保护您的账户及信息安全。如果您对本政策有任何疑虑，您可以发邮件至{{appEmail}}与{{appName}}客服联系。</p>
      <p class="indent">如果您未满18周岁，请您及您的法定监护人均仔细阅读、充分理解本政策，您应该确保在全部接受本政策内容并征得法定监护法定监护人的同意后使用{{appName}}。
      </p>
      <p class="indent bb1">本政策适用于在中华人民共和国境内(港、澳、台地区除外)收集、使用、存储及对外提供您的个人信息的行为。</p>
      <h3>一、本政策将帮助您了解以下内容</h3>
      <p>（1）我们如何收集您的个人信息；</p>
      <p>（2）我们如何使用您的个人信息；</p>
      <p>（3）我们如何保存您的个人信息；</p>
      <p>（4）我们如何使用Cookie和同类技术；</p>
      <p>（5）我们如何共享、转让、公开披露您的个人信息；</p>
      <p>（6）第三方产品或服务如何获得您的个人信息；</p>
      <p>（7）我们如何保护您的个人信息；</p>
      <p>（8）您所享有的数据主体权利；</p>
      <p>（9）我们如何处理未成年人的个人信息；</p>
      <p>（10）本政策如何更新；</p>
      <p>（11）如何联系我们；</p>

      <p class="indent mt30">在您使用{{appName}}的过程中，我们会按照如下方式收集和使用您的个人信息，以向您提供我们的产品或服务、优化我们的产品或服务的质量以及保障产品或服务的安全与稳定运行：</p>
      <h4>1.完成注册并完善信息</h4>
      <p>作为个人用户，当您用手机登录/注册{{appName}}时，您可能需要向我们提供您的手机号码；当您用第三方账户（微信）登录/注册{{appName}}时，我们可能需要收集您的第三方账户的头像、昵称以及第三方开放平台用户的标识（微信的用户标识为openid）。我们收集您的上述信息是为了帮助您完成{{appName}}账户的注册，保护您的账户安全，如果您不提供这类信息，我们将无法为您创建账户并正常向您提供服务。</p>
      <h4>2.账户绑定</h4>
      <p>如果您需要为您的{{appName}}账户绑定其他的社交账户（例如微信），我们可能从该等第三方获取您授权共享的账户信息（头像、昵称）以及第三方开放平台用户的标识（微信的用户标识为openid），并在您同意本隐私政策后将您的第三方账户与您的{{appName}}账户绑定，使您可以通过第三方账户直接登录并使用{{appName}}平台，并便于您向相关平台分享您的创作。如果您不提供该等信息，您将无法将您的账户与其他社交账户关联，但不影响您正常使用{{appName}}的其他业务功能。</p>
      <h4>3.创作工具</h4>
      <p>当您使用我们为您提供的创作工具时，我们可能会收集您上传的网络行为轨迹（如点击次数、使用次数）以便于您使用该工具。如果您不同意我们收集上述信息，您可能无法使用我们向您提供的创作工具，但不影响您正常使用{{appName}}的其他业务功能。</p>
      <h4>4.支付功能</h4>
      <p>当您使用{{appName}}付费服务产生支付时，我们会收集您的订单信息、使用记录、账户信息（如ITunes账户），这是为您提供该服务所必需的。</p>
      <h4>5.处理侵权通知</h4>
      <p>如果您发现，{{appName}}中，用户上传内容侵犯您的知识产权的，您依据{{appName}}公布的《用户协议》中投诉及处理程序向我们发送侵权通知的，我们将会收集您主动向我们提交的姓名、身份证明文件复印件（如身份证、护照）、手机号码、电话号码、通信地址、传真和电子邮件。如果您不同意提供上述信息，该侵权通知可能不构成合格侵权通知，可能致使您投诉失败，但不影响您正常使用{{appName}}的其他业务功能。</p>
      <h4>6.保障网络访问和服务的基本安全</h4>
      <p>为了保障服务的安全性，我们可能会收集您的唯一设备识别符、IP地址、访问日期和时间、服务日志信息。我们收集您的上述信息是为了保障应用运行安全，如果您不提供上述信息，则我们无法保证您在使用期间的应用运行安全。</p>
      <h4>7.客户服务</h4>
      <p>在您通过我们的反馈系统向我们提出咨询、投诉、建议或进行反馈时，为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会收集您与我们的对话记录、您提供的账户信息、您提供的联系方式信息、您为了证明相关事实提供的信息（如问题描述、附件）。我们收集上述信息是为了完成客户服务，如果您不提供这类信息，您的投诉、建议和反馈可能无法得到及时、有效处理，但不影响您正常使用我们的其他服务。</p>
      <p>请您理解，我们向您提供的功能和服务是不断更新和发展的，如果某一功能或服务未在前述说明中且收集了您的信息，我们会通过页面提示、网站公告等方式另行向您说明信息收集的内容、范围和目的，以征得您的同意。</p>
      <h4>8.征得授权同意的例外</h4>
      <p>根据相关法律法规及国家标准，以下情形中，我们可能会收集、使用您的相关个人信息而无需征求您的授权同意：</p>
    <p>
      （1）为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；
    </p>
    <p>（2）为履行法定职责或者法定义务所必需；</p>
    <p>（3）为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</p>
    <p>（4）为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；</p>
    <p>（5）依照本法规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；</p>
    <p>（6）法律、行政法规规定的其他情形。</p>
    <h4>9.涉及嵌入第三方代码、插件传输个人信息的情形逐项距离：</h4>
  <p>（1）微信</p>
  <p>应用目的或场景：用户通过微信登录收集及共享个人信息的类型/范围：微信昵称、头像、openID；获取已安装App信息SDK服务提供商：腾讯公司隐私政策链接：https://privacy.qq.com/policy/tencent-privacypolicy</p>
  <h4>10.设备信息收集</h4>
  <p>为识别您的设备ID并预防恶意程序及反作弊、提高服务安全性、保障运营质量及效率，我们会收集您的设备信息（包括IMEI、MEID、Android ID、IMSI、GUID、MAC地址、SD卡数据）、您安装的应用信息或运行中的进程信息</p>
  <h3>二、我们如何使用您的个人信息</h3>
  <p>1.我们会根据本政策的约定并为实现我们的服务的目的对所收集的个人信息进行使用。</p>
  <p>2.请您注意，您在使用我们的服务时所提供的所有个人信息，除非您撤回相关授权、删除或通过系统设置拒绝我们收集，否则将在您使用我们的服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除您的个人信息。</p>
  <p>3.我们会对我们提供的服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以用于产品开发、服务优化、安全保障的目的。但这些统计信息是去标识化后的信息。
  4.当我们要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会通过您主动做出勾选的形式事先征求您的同意。</p>
  <h3>三、我们如何保存您的个人信息</h3>
  <p>1.我们将在本政策载明的目的所需及法律法规要求的最短保存期限之内，保存您的个人信息。前述期限届满后，我们将对您的个人信息做删除或匿名化处理。</p>
  <p>2.当我们的产品或服务发生停止运营或注销账号的情形时，我们将采取推送通知、公告等形式通知您，并在合理的期限内删除或匿名化处理您的个人信息，法律法规另有规定的除外。（所谓“匿名化处理”，是指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。个人信息经匿名化处理后所得的信息不属于个人信息。）</p>
  <p>3.我们会按照法律法规规定，将境内收集的用户个人信息存储于中国境内。如果您的个人信息存储地点从中国境内转移到境外的，我们将严格依照法律的规定执行。</p>
  <h3>四、我们如何使用Cookie和同类技术</h3>
  <p>1.我们可能通过cookie、token等方式收集和使用您的个人信息，token收集的个人信息包括用户id、用户名，且token信息为密文，并将该等信息储存为日志信息。您可以选择通过修改浏览器设置的方式拒绝cookie，通过不登录方式拒绝token，但您可能无法登录或使用依赖于cookie、token的我们提供的服务或功能。</p>
  <p>2.我们使用自己的cookie、token等方式收集信息的目的是为您提供更为优质的服务，其用途主要在于：识别和记住您的用户身份；保存您向我们提供的用户偏好和其他信息；分析您使用我们服务的情况和您的活动偏好。</p>
  <h3>五、我们如何保护您的个人信息</h3>
  <p>1.我们非常重视用户个人信息的安全。为此，我们采取了行业通行的数据保护技术与管理措施，例如网络隔离、备份、数据加密、员工访问控制等措施。</p>
  <p>2.互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</p>
  <p>3.在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施、个人信息处理者的联系方式等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</p>
  <h3>六、您所享有的数据主体权利</h3>
  <h4>1.访问及更正您的个人信息</h4>
  <p>如果您希望访问或更正您的个人信息，例如账户基本信息，您可以在【个人设置】中进行访问和更正。</p>
  <h4>2.删除您的个人信息</h4>
  <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
  <p>（1）处理目的已实现、无法实现或者为实现处理目的不再必要；</p>
  <p>（2）个人信息处理者停止提供产品或者服务，或者保存期限已届满；</p>
  <p>（3）个人撤回同意；</p>
  <p>（4）个人信息处理者违反法律、行政法规或者违反约定处理个人信息；</p>
  <p>（5）法律、行政法规规定的其他情形。</p>
  <p>法律、行政法规规定的保存期限未届满，或者删除个人信息从技术上难以实现的，我们将停止除存储和采取必要的安全保护措施之外的处理。</p>
  <h4>3.注销账户</h4>
  <p>如果您希望注销您的账户，您可以通过本隐私政策载明的方式与我们联系，在提供与账号相符的本人身份证明后，注销本人账号。</p>
  <p>您上述注销账户的行为是不可逆的，我们将停止为您提供产品或服务，不再收集您的个人信息，并依据您的要求删除与您账户相关的个人信息或做匿名化处理。</p>
  <h4>4.取消促销信息通知</h4>
  <p>如果您不想接受我们给您发送的促销信息，您随时可通过以下方式取消：</p>
  <p>（1）您可以根据促销短信内容提示，来取消我们给您发送的手机促销短信。</p>
  <p>（2）您可以通过移动端APP设置是否接受我们通过“通知”推动给您的商品和促销信息。</p>
  <h4>5.逝者近亲属的权利</h4>
  <p>您知悉，根据我国现行法律规定，如{{appName}}用户不幸逝世，其近亲属可通过{{appName}}客服官方渠道联系我们，以获取相关指引来行使法律规定的合法、正当权益。</p>
  <h4>6.响应您的上述请求</h4>
  <p>如果您无法按照上述方式行使权利的，您可以采取本隐私政策载明的联系方式与我们联系。为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在收到您反馈并验证您的身份后的15天内答复您的请求。</p>
  <p>对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。</p>
  <h4>7.响应情形的例外</h4>
  <p>在以下情形中，我们将无法响应您的请求:</p>
  <p>（1）与我们履行法律法规规定的义务无关的；</p>
  <p>（2）与国家安全、国防安全直接相关的；</p>
  <p>（3）与公共安全、公共卫生、重大公共利益直接相关的；</p>
  <p>（4）与刑事侦查、起诉、审判和执行判决等直接相关的；</p>
  <p>（5）我们有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
  <p>（6）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
  <p>（7）响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；</p>
  <p>（8）涉及商业秘密的。</p>
  <h3>七、我们如何处理未成年人的个人信息</h3>
  <p>1.我们非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用我们的产品与/或服务前，应事先取得您监护人的同意。我们将根据国家相关法律法规的规定保护未成年人的个人信息。</p>
  <p>2.如果您为未成年人（尤其是不满14周岁的未成年人），我们要求您请您的父母或其他监护人仔细阅读本隐私权政策，并在征得您的监护人授权同意的前提下使用我们的服务或向我们提供信息。</p>
  <p>对于可能涉及的不满14周岁的儿童个人信息，我们进一步采取以下措施予以保障：</p>
  <p>（1）对于收集到的儿童个人信息，我们除遵守本隐私政策关于用户个人信息的约定外，还会秉持正当必要、知情同意、目的明确、安全保障、依法利用的原则，严格遵循《儿童个人信息网络保护规定》等法律法规的要求进行存储、使用、披露，且不会超过实现收集、使用目的所必须的期限，到期后我们会对儿童个人信息进行删除或匿名化处理。我们会指定专人负责儿童个人信息保护事宜，并设立了儿童个人信息保护的专门邮箱{{appEmail}}。我们还会制定儿童个人信息保护的内部专门制度。</p>
  <p>3.如果您是未成年人的监护人，请您关注您所监护的未成年人是否是在您授权同意之后使用我们的产品或服务。如果您对您所监护的未成年人的个人信息有疑问，请通过本隐私政策载明的方式与我们联系。</p>
  <h3>八、本隐私政策如何更新</h3>
  <p>1.我们的隐私政策可能会适时发生变更。我们会在本页面上发布对本隐私政策所做的任何变更。对于重大变更，我们还会提供更为显著的通知（我们可能会通过在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。</p>
  <p>2.本隐私政策所指的重大变更包括但不限于：我们的服务模式发生重大变化；个人信息共享、转让或公开披露的主要对象发生变化；您参与个人信息处理方面的权利及其行使方式发生重大变化；我们的联系方式及投诉渠道发生变化；个人信息安全影响评估报告表明存在高风险时。</p>
  <h3>九、如何联系我们</h3>
  <p>1.如果您对本政策或个人信息保护有任何问题，您可以将您的书面疑问、意见或建议通过以下地址寄至客服部门：</p>
  <p>名称：北京飞扬广告有限公司AI客服部</p>
  <p>地址：北京市朝阳区达美中心T3  809-812</p>
  <p>或您也可以通过以下电话与客服部门联系：</p>
  <p>联系邮箱： {{appEmail}} </p>
  <p>2.一般情况下，我们将在十五个工作日内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向相关监管部门进行投诉或举报，或通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。</p>
    </div>
  </template>
  <script>
  export default {
    data () {
      return {
				appName: this.$store.state.setApplicationInfo.title,
				appEmail: this.$store.state.setApplicationInfo.email
      }
    }
  }
  </script>
  <style lang="less" scoped>
  .agreement-service{
    .tright{
      text-align: right;
    }
    h3{
      margin-top: 16px;
      margin-bottom: 16px;
      text-align: center;
    }
    .indent{
      text-indent: 2em;
    }
    .bb1{
      text-decoration: underline!important;
      font-weight: 500;
    }
    .mt30{
      margin-top: 30px;
    }
    p{
      margin-bottom: 10px;
    }
    h4{
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  </style>
