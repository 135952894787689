
// baseWebHost 本地域名
let baseWebHost = window.location.host.replace('www.', '')
if (window.location.hostname === 'localhost' || baseWebHost.indexOf('192.168.') > -1) {
  baseWebHost = 'https://yz.feiyangai.cn'
} else {
  baseWebHost = window.location.protocol + '//' + baseWebHost
}
const VUE_APP_ApiHost = process.env.VUE_APP_ApiHost
// const VUE_APP_WebHost = process.env.VUE_APP_WebHost

export default {
  host: VUE_APP_ApiHost,
  // webHost: VUE_APP_WebHost
  webHost: baseWebHost
}
