<template>
  <div id="app">
    <!-- Nav -->
    <div v-if="isPC">
      <router-view :key="componentKey"/>
        <!-- 充值提醒 -->
      <ChargeTips
        v-if="showChargeTips"
        v-model="showChargeTips"
        >
      </ChargeTips>
      <!-- 对话弹窗 -->
        <input-prompt
          v-if="inputPromptShow"
          :chooseItem="appInfo"
        ></input-prompt>
    </div>
    <div v-else>
      <UpdatePop></UpdatePop>
    </div>
  </div>
</template>
<script>
import UpdatePop from '@/components/UpdatePop.vue'
import ChargeTips from '@/components/ChargeTips.vue'
import InputPrompt from '@/components/InputPrompt.vue'
import FirstNotice from "@/components/FirstNotice.vue";

import {mapGetters, mapState} from 'vuex'
import { isPc } from '@/util/common'

export default {
  components: {FirstNotice, UpdatePop, ChargeTips, InputPrompt },
  data() {
    return {
      NavRouterArr: ['/home', '/chat', '/chathisory', '/createnum', '/share', '/editor', '/sketch-to-image', '/ai-matting', '/ai-inpainting', '/image-upscaler', '/background-bokeh', '/replace-background','/ai-imitate-image'], // 创建、登录 不显示菜单
      componentKey: new Date().getTime(),
      isPC: isPc(),
      showChargeTips: false // 充值提示
    }
  },
  computed: {
    ...mapGetters(['appInfo', 'inputPromptShow']),
    ...mapState({
      useInfo: (state) => state.useInfo,
        isRegister: (state) => state.isRegister,
    }),
  },
  mounted() {
    // get-set-config
    // 获取网站title
    this.getTitle()
    document.title = ''

    this.$bus.$off('Refresh:view')
    this.$bus.$on('Refresh:view', (v) => {
      if (v) this.componentKey = new Date().getTime()
    })
    this.$bus.$off('listenChargeTips')
    this.$bus.$on('listenChargeTips', (v) => {
      if (v) {
        this.showChargeTips = true
      }
    })
  },
  methods: {
    getTitle () {
      this.$request('index/get-set-config',
        { member_token: (this.useInfo && this.useInfo.member_token) || '' },
              'get'
        ).then((rs) => {
            if (rs && rs.code === 200){
							const title = rs.data && rs.data.title
              document.title = title
	            this.$store.commit('setApplicationInfo', rs.data)
            }
          })
    }
  }
}
</script>

<style lang="less" scoped>
#app {
  width: 100%;
  min-height: 100vh;
  background: #f7f7f7;
}
</style>
