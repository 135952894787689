
import { mapState } from 'vuex'

export default {
  data() {
    return {
      logo: ''
    }
  },
  computed: {
    ...mapState({
      useInfo: (state) => state.useInfo,
    }),
  },
  methods: {
     // 获取logo
    //  logo_type:1首页  2登录页面 3pc底部
     getLogo (logo_type='1'){
      this.$request(
        'index/logolist',
        {
          member_token: (this.useInfo && this.useInfo.member_token) || '',
        },
        'get'
      ).then((rs) => {
        if (rs && rs.code === 200) {
          if (rs && rs.data) {
            let list  = rs.data
            let resultList = list && list[0]? list.filter(v => v.logo_type === logo_type)[0]:[]
            this.logo = resultList.logo_image || ''
          }
        }
      })
    },
  }
}