<template>
  <div class="agreement-privacy">
<p class="tright">生效日期：2022年07月30日</p>
<h3>一、导言</h3>
<p>1.1北京飞扬广告有限公司（以下简称“我方”或“原则”）系“{{appName}}”产品及相关服务（以下统称“{{appName}}”）的运营者。</p>
<p>1.2为了更好地为您提供服务，请您在开始使用{{appName}}之前，认真阅读并充分理解本协议，特别是涉及免除或者限制责任的条款、权利许可和信息使用的条款、法律适用和争议解决条款等。其中，免除或者限制责任条款等重要内容将以加粗、下划线等形式提示您注意，您应重点阅读，并确保您在已同意前述条款的有效性的前提下，使用{{appName}}。</p>
<p>如果您对本协议有任何疑虑，您可以发邮件至{{appEmail}}与{{appName}}客服联系。<span class="bb1">如果您对本协议的任何条款持有异议，请您停止注册或使用{{appName}}。如果您继续注册或使用{{appName}}，则视为您已充分理解本协议，并同意作为本协议的一方当事人接受本协议以及其他与{{appName}}有关的协议和规则（包括但不限于《隐私政策》）的约束。</span></p>
<p class="bb1">如果您未满18周岁，请您及您的法定监护人均仔细阅读、充分理解本协议，您应该确保在全部接受本协议内容并征得法定监护人的同意后使用{{appName}}。</p>
<p>1.3本协议是您与我方就您使用{{appName}}所订立的协议，具有合同效力。无论您是进入{{appName}}浏览网页，还是在{{appName}}上发布任何内容，或者是直接或通过各类方式（如站外API引用等）间接使用{{appName}}的行为，都将被视作已无条件接受本声明所涉全部内容。</p>
<p>我方有权对本协议进行修改，且将以发送通知等方式公布修改后的协议，修改后的协议一经公布即有效的代替原协议。如果您不同意本协议的修改，请立即停止访问或使用{{appName}}，或取消已经获得的服务；如果您选择同意或继续访问、使用{{appName}}，则视为您已接受本协议的修改。</p>
<p>1.4在您使用{{appName}}某一特定服务时，该服务可能会另有单独的协议、相关业务规则等。前述内容将以网站公告、通知等多种形式公布，一经正式发布，即视为本协议不可分割的组成部分，与本协议具有同等法律效力，您同样应当遵守。本协议与上述内容存在冲突的，以特定服务的单独协议和相关业务规则为准。</p>
<h3>二、用户登录与账户管理</h3>
<p>2.1为了方便您使用{{appName}}，您可以通过手机号码验证登录{{appName}}，或者微信扫码登录{{appName}}，也可以选择作为匿名用户仅享受浏览、信息查阅等部分功能及服务。您的手机号/微信号即为您的{{appName}}账户，该账户将记录您使用{{appName}}时的活动。无论何种方式，您均应遵守本协议及各项业务规则等条款的约定。</p>
<p>2.2请您妥善保管您的账户，因您原因导致账户泄露而造成的法律后果由您个人负责。同时，您还应当对以此账户登录进行的所有活动和事件承担全部后果与法律责任。</p>
<p>2.3同时，需要提示您注意的是，<span class="bb1">拥有{{appName}}账户也并不意味着您可以使用{{appName}}提供的全部服务。为保障用户合法权益及交易安全，{{appName}}可能会就某些功能、服务的开通，要求您提供银行账户信息或其他身份资料和信息，以便进行身份认证或资格验证。您必须依{{appName}}要求提示提供真实、最新、有效及完整的资料。</span></p>
<p>对于{{appName}}会如何收集、使用、存储和保护你的个人信息及你享有何种权利，你还可以阅读<span class="bb1">《{{appName}}隐私政策》</span>予以进一步了解。</p>
<p>2.4您知悉并认可，因您丢失{{appName}}会员账户，而致使无法使用您所订购的收费服务的，我方对此不承担任何责任。</p>
<p>2.5您有权决定终止使用{{appName}}账户。<span class="bb1">当您决定不再使用时，您应在清偿所有应付款项（包括但不限于本金、利息、违约金、服务费等）后，将您{{appName}}账户中的可用款项（如有）全部取现或者向{{appName}}发出其他合法的支付指令，并向{{appName}}申请注销该用户账户，经{{appName}}审核同意后可正式注销您的账户。</span>注销成功后，账户信息、文档信息、交易记录、会员权益等将无法恢复或提供。</p>
<h3>三.{{appName}}的服务</h3>

<p>3.1您使用{{appName}}，可以通过访问{{appName}}官方网站<a class="Cblue" href="https://ai.fly-ad.cn">https://ai.fly-ad.cn</a>；</p>
<p>3.2{{appName}}是一款在线问答创作工具。您使用{{appName}}完成创作后，可直接复制内容使用；</p>
<p>3.3{{appName}}的服务主要包括：用户可进行某场景的问答创作。其他各项服务，具体服务以我方实际提供的为准。</p>
<p>3.4{{appName}}提供的部分服务为付费服务（以下简称“付费服务”），您选择使用付费服务时需要向{{appName}}支付一定的费用。<span class="bb1">在您开通该等服务之前，{{appName}}会进行明确的付费提示，只有您根据提示愿意支付相关费用，您才能开通并使用该付费服务。</span>如您拒绝支付相关费用，则{{appName}}有权不向您3.4.1{{appName}}提供不同期限的付费服务，以您自行选择并支付相应费用购买的期限为准，且{{appName}}有权根据运营情况变更付费价格与优惠程度。</p>
<p>3.5我方可能根据实际运营情况对所提供的收费服务的收费模式、收费标准、方式进行修改和变更，我方也可能会对所提供的目前为免费使用的服务开始收费。在前述修改、变更或开始收费之前，我方将在相应服务页面进行通知或公告。如果您不同意上述修改、变更或付费内容，则应停止使用该部分服务。</p>
<h3>四.用户使用与内容发布</h3>
<p class="bb1">4.1任何情况下，您在中国大陆地区范围外登录并使用{{appName}}，属于超出授权范围使用的行为，因此我方对于能否登录或正常使用{{appName}}不作担保或保证。如果您在超出授权范围使用时遭受任何损失，我方无法为此向您或任何第三方承担任何责任。</p>
<p>4.2您在使用{{appName}}过程中，不得以任何方式利用{{appName}}直接或间接从事违反中国法律、以及社会公德的行为，应当遵守尊重不同地区、地域的道德和风俗习惯。请勿上传、提交、发布、存储、发送、接收、传播或分享以下内容：</p>
<p>4.2.1.反对宪法确定的基本原则的；</p>
<p>4.2.2危害国家统一、主权和领土完整的；</p>
<p>4.2.3泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；</p>
<p>4.2.4煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；</p>
<p>4.2.5宣扬邪教、迷信的；</p>
<p>4.2.6扰乱社会秩序，破坏社会稳定的；</p>
<p>4.2.7诱导未成年人违法犯罪和渲染暴力、色情、赌博、恐怖活动的；</p>
<p>4.2.8侮辱或者诽谤他人，侵害公民个人隐私等他人合法权益的；</p>
<p>4.2.9危害社会公德，损害民族优秀文化传统的；</p>
<p>4.2.10骚扰、广告信息、过度营销信息及垃圾信息。</p>
<p>4.3{{appName}}有权对您使用{{appName}}的情况进行审查和监督，如果您在使用{{appName}}时违反任何上述规定，我方或授权代表有权要求您改正或直接采取一切必要的措施（包括但不限于更改或禁止生成内容答案、作品，暂停或终止您使用{{appName}}的权利）以减轻您的不当使用行为造成的影响。</p>
<p>4.4如果您违反上述规定并造成严重后果的，我方除暂停或终止对您的服务、解除本协议外，无需退还您所支付的费用（如有），视为您支付的违约金，如违约金不足以弥补我方损失的，我方还可通过其他法律途径向您追索。</p>
<h3>五.设备</h3>
<p>5.1您应当理解，当您使用{{appName}}时需自行准备与相关服务有关的终端设备（如电脑、手机、摄像头、麦克风等装置），并承担所需的费用（如短信费、上网费等费用）</p>
<p>5.2您理解并同意，您使用{{appName}}时会耗用您的终端设备和带宽等资源。</p>
<h3>六.知识产权与规范使用</h3>
<p>6.1{{appName}}在全世界范围内的全部知识产权归我方独家永久享有，包括但不限于{{appName}}相关的商标、图饰、图像、图表、色彩、界面设计、版面框架、有关数据、附加程序、印刷材料或电子文档等，受著作权法和国际著作权条约以及其他知识产权法律法规的保护。</p>
<p>6.2除非法律允许或经我方书面许可，您使用{{appName}}的过程中不得从事下列行为：</p>
<p>6.2.1删除{{appName}}及其副本上关于著作权的信息；</p>
<p>6.2.2对{{appName}}相关软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本软件的源代码；</p>
<p>6.2.3对我方拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版等；</p>
<p>6.2.4对{{appName}}运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非我方经授权的第三方工具/服务接入本软件和相关系统；</p>
<p>6.2.5通过修改或伪造{{appName}}运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；</p>
<p>6.2.6通过非我方开发、授权的第三方软件、插件、外挂、系统，登录或使用{{appName}}，或制作、发布、传播上述工具；</p>
<p>6.2.7自行或者授权他人、第三方软件对{{appName}}及其组件、模块、数据进行干扰；</p>
<p>6.2.8其他未经我方明示授权的行为。</p>
<h3>七.违约及处理</h3>
<p>7.1发生如下情形之一的，视为您违约：</p>
<p>7.1.1.使用{{appName}}时违反有关法律法规规定的；</p>
<p>7.1.2.侵犯我方及任何第三方合法利益的；</p>
<p>7.1.3.违反本协议的任何约定。</p>
<p>7.2.对于您的违约行为，我方将采取如下处理措施：</p>
<p>7.2.1.您在{{appName}}中发布、存储的信息违法违约的，我方可根据相应规则立即对相应信息进行删除、屏蔽处理；</p>
<p>7.2.2.您在{{appName}}中实施违约行为，我方可根据违约程度，限制您的账户使用、暂停对您账户的访问或冻结您的账户。</p>
<p>7.3对于您的违约行为，您应依法承担违约责任：</p>
<p>7.3.1.若因您的任何违约行为致使我方被追究责任或使我方利益受损的，您应为我方提供抗辩并赔偿我方因此遭受的损失，损失难以确认的，赔偿金额包括您因违约行为所获得的一切直接、间接利益，以及我方因此所需支付的包括但不限于诉讼费用、调查费用、律师费用、公证费用等一切合理费用。</p>
<p>7.3.2由此导致您或第三方遭受任何损失（包括但不限于通信中断，用户资料、邮件和虚拟财产及相关数据、增值服务、产品或服务等的丢失、毁损、清空或无法使用等损失）我方不承担任何责任，且我方无义务为您或第三方提供任何内容回复或备份服务。</p>
<p>7.3.3由此导致您无法继续使用您已支付费用的{{appName}}的任何部分或全部服务，我方均无需向您退还任何服务费用。</p>
<p>7.3.4.我方有权交由有关部门依照相关法律法规规定处理，且我方保留要求您承担相应法律责任的权利。</p>
<h3>八.不可抗力与责任限制</h3>
<p>8.1您理解并同意，在使用{{appName}}服务的过程中，可能会遇到不可抗力等风险因素，使{{appName}}提供的服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等，以及社会事件如战争、动乱、政府行为等。出现上述情况时，{{appName}}将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失，{{appName}}在法律允许的范围内免责。</p>
<p>8.2您理解，我方的服务是按照现有技术和条件所能达到的现状提供的。我方将尽最大努力确保服务的连贯性和安全性，但我方不能保证所提供的服务毫无瑕疵。所以您也同意，即使我方提供的服务存在瑕疵，但若上述瑕疵是当时行业技术水平所无法避免的，其将不被视为我方违约，双方应友好协作共同解决问题。</p>
<p>8.3在法律允许的范围内，{{appName}}对以下情形导致的服务中断或受阻不承担责任：</p>
<p>8.3.1受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；</p>
<p>8.3.2用户或{{appName}}的电脑软件、系统、硬件和通信线路出现故障；</p>
<p>8.3.3用户操作不当；</p>
<p>8.3.4用户通过非{{appName}}授权的方式使用{{appName}}服务；</p>
<p>8.3.5其他{{appName}}无法控制或合理预见的情形。</p>
<p>8.4为了{{appName}}的正常运行，我方会定期或不定期对{{appName}}的相关网站、服务器等进行维护，因此类情况而造成{{appName}}账户无法登录，请您给予理解。我方将尽合理努力事先进行通告，并尽力无法登录，或将无法登录的时间限制在最短时间内。</p>
<p>8.5您理解并同意，在使用{{appName}}的过程中，可能会遇到网络信息或其他用户行为带来的风险，{{appName}}不对非{{appName}}发布的任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给您造成的损害负责。这些风险包括但不限于：</p>
<p>8.5.1来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息；</p>
<p>8.5.2因使用本协议项下的服务，遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失；</p>
<p>8.5.3其他因网络信息或用户行为引起的风险。</p>
<p>8.6在任何情况下，您不应轻信借款、索要验证码或其他涉及财产的网络信息。涉及财产操作的，请一定先核实对方身份，并请经常留意{{appName}}或其他部门发布的有关防范诈骗犯罪的提示。</p>
<p>8.7您理解并认可，您在{{appName}}创作的内容仅表明个人的立场和观点，并不代表{{appName}}的立场或观点。作为内容的发表者，您需自行对所发表内容负责，因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律责任，{{appName}}不承担任何法律及连带责任。</p>
<p>8.8您作为创作者在{{appName}}发布的作品，应具有独立、完整的知识产权，不得侵犯他人知识产权等任何权利。若您上传的作品及作品中相关素材（包括但不限于内容、排版、创意、文字、图片、图标及字体等）侵犯了任何第三方权利，平台有权在收到相关侵权投诉后对该被投诉作品或用户账号，采取包括但不限于下架、警告封禁、诉诸法律途径介入等处理方式。同时，有权扣除违反相应作品的收益，已结算的部分，您应该予以返还。您知悉、理解并同意，您通过本平台上传、发布到{{appName}}的作品将授予飞扬广告在全球范围内用于品牌或产品宣传。</p>
<h3>九.投诉处理</h3>
<p>9.1{{appName}}受理侵犯企业或个人合法权益的侵权举报，包括但不限于涉及个人隐私、造谣与诽谤、商业侵权。</p>
<p>9.1.1涉及个人隐私：发布内容中直接涉及身份信息，如个人姓名、家庭住址、身份证号码、工作单位、私人电话等详细个人隐私；</p>
<p>9.1.2造谣、诽谤：发布内容中指名道姓（包括自然人和企业）的直接谩骂、侮辱、虚构中伤、恶意诽谤等；</p>
<p>9.1.3商业侵权：泄露企业商业机密及其他根据保密协议不能公开讨论的内容。</p>
<p>9.2如果您认为其他用户通过{{appName}}上传、发布、传播或分享的作品侵犯了您的合法权利，请根据本款规定的程序向我方发出正式通知（电子邮箱：{{appEmail}}；通知中应包含以下信息：</p>
<p>（1）您的姓名（名称）、联系方式和地址；</p>
<p>（2）要求删除或者断开链接的侵权作品、表演、录音录像制品的名称和网络地址；</p>
<p>（3）构成侵权的初步证明材料；</p>
<p>（4）在通知上签名，如果通知由公司或组织提交，应加盖公司或组织的公章。</p>
<p>为了保证问题能够及时有效地处理，请务必提交真实有效、完整清晰的材料，否则不予受理。我方有权复制收到的任何通知并将其寄送至第三方，且我方可以在已删除内容的原先位置或网站的其他区域发表您的通知。</p>
<p>我方将在收到您的通知来函后，对所有完整且合格的通知做出回应和/或采取行动，并保留经由电子邮件或其他方式与您沟通的权利。同时，如果收到前述通知的用户向我方发出反向通知，我方在收到反向通知后，我方可以恢复该通知涉及的材料。</p>
<p>如果您需向我方发出反向通知，您必须向我方提供包含以下信息的书面通知：</p>
（1）您的姓名（名称）、联系方式和地址；
（2）要求恢复的作品、表演、录音录像制品的名称和网络地址；
（3）不构成侵权的初步证明材料；
（4）在反向通知上签名，如果通知由公司或组织提交，应加盖公司或组织的公章。
<p>您必须对反向通知的内容的真实性负责。</p>
<p class="bb1">敬请注意，如果您虚假陈述任何内容或活动并未侵犯他人著作权，您将承担损害赔偿责任（包括诉讼费和律师费）。因此，如果您不确定某些材料是否对他人著作权构成侵权，我方建议您先行与专业法律顾问进行沟通。</p>
<p>9.3我方对于投诉的处理流程：</p>
<p>9.3.1您认同，我方对于投诉信息是否属于平台监督范围有权独立做出判断，并非所有申请我方都必须受理。我方受理的投诉，将在自收到相关材料七个工作日内处理完毕并给出回复。处理期间，不提供任何电话、邮件及其他方式的查询服务。</p>
<p>9.3.2如果出现{{appName}}已经删除或处理的内容，但是百度、谷歌等搜索引擎依然可以搜索到的现象，是因为百度、谷歌等搜索引擎自带缓存，此类问题{{appName}}无权也无法处理，因此相关申请不予受理。</p>
<p>9.3.3此为{{appName}}官方的侵权投诉渠道，暂不提供其他方式处理此业务。用户在{{appName}}的商业行为引发的法律纠纷，由交易双方自行处理，与我方无关。</p>
<h3>十.服务的终止</h3>
<p>10.1当且仅当我方自行决定终止{{appName}}中某一项或某几项单独收费服务运营的情况下，对于该等被终止的单独收费服务中您已经订购但尚未使用的部分（如有），我方将以银行转账方式或我方与您共同接受的其他方式，将剩余的服务费用退还与您。</p>
<p>10.2除前述情况之外的任何情况下，我方均无需向您退还任何服务费用，本款约定如与本协议其他条款约定发生冲突均以本款约定为准。</p>
<p>10.3我方给予您30个工作日的期限用于您对其已经订购但尚未使用的该项服务部分申请退费。您认可上述申请退费期限的合理性，并应在上述期限内，依照届时我方公布的具体政策，申请办理退费手续。上述申请退费期限届满，您提交的退费申请（需符合我方届时公布的具体政策）未能到达我方届时指定接受地址的，视为您自动放弃该项申请退费权利，我方有权拒绝进行退费。</p>
<h3>十一.法律适用与争议解决</h3>
<p>11.1本协议签订地为中华人民共和国北京市朝阳区。</p>
<p>11.2本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（仅为本协议之目的，不包括香港、台湾、澳门地区）。</p>
<p>11.3若您和我方就本协议发生任何纠纷或争议，首先应友好协商解决；协商不成的，任何一方应将纠纷或争议提交本协议签订地人民法院管辖。</p>
<p>11.4我方未行使或执行本协议任何权利或规定，不构成对前述权利或权利之放弃。</p>
<p>11.5本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。</p>
<p>11.6本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。</p>
<h3>十二.未成年人使用</h3>
<p>12.1若您未满18周岁，则为未成年人，请在监护人监护、指导下阅读本协议和使用{{appName}}。</p>
<p>12.2未成年人用户涉世未深，容易被网络虚象迷惑，且好奇心强，遇事缺乏随机应变的处理能力，很容易被别有用心的人利用而又缺乏自我保护能力。因此，未成年人用户在使用{{appName}}时应注意以下事项，提高安全意识，加强自我保护：</p>
<p>12.2.1认清网络世界与现实世界的区别，避免沉迷于网络，影响日常的学习生活；</p>
<p>12.2.2填写个人资料时，加强个人保护意识，以免不良分子对个人生活造成骚扰；</p>
<p>12.2.3在监护人或老师的指导下，学习正确使用网络；</p>
<p>12.2.4避免陌生网友随意会面或参与联谊活动，以免不法分子有机可乘，危及自身安全；</p>
<p>12.2.5在使用{{appName}}提供的相关付费服务前，应当取得监护人同意，并使用合法的自有财产获得该等付费服务。</p>
<p>12.3监护人、学校均应在未成年人使用{{appName}}时多做引导。特别是家长应关心子女的成长，注意与子女的沟通，指导子女上网应该注意的安全问题，防患于未然。</p>
<h3>十三.其他</h3>
<p>13.1本协议是您与我方之间关于您使用{{appName}}所订立的协议。</p>
<p>13.2即便您没有点击同意本协议，下述行为也将视为您对本协议所有条款的认可：</p>
<p>13.2.1点击登录{{appName}}；</p>
<p>13.2.2实际使用{{appName}}；</p>
<p>13.2.3实际使用第三方连同{{appName}}一并提供的任何产品或服务（如有）。</p>
<p>13.3如果您对本协议或{{appName}}有意见或建议，可与{{appName}}客服邮箱<span class="bb1">{{appEmail}}</span>联系，我方会给予您必要的帮助。</p>

  </div>
</template>
<script>
export default {
  data () {
    return {
	    appName: this.$store.state.setApplicationInfo.title,
	    appEmail: this.$store.state.setApplicationInfo.email
    }
  }
}
</script>
<style lang="less" scoped>
.agreement-privacy{
  .tright{
    text-align: right;
  }
  h3{
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;
  }
  .indent{
    text-indent: 2em;
  }
  .bb1{
    text-decoration: underline!important;
    font-weight: 500;
  }
  a.Cblue{
    text-decoration: underline!important;
    margin-left: 4px;
  }
  .mt30{
    margin-top: 30px;
  }
  p{
    margin-bottom: 10px;
  }
  h4{
    margin-top: 20px;
    margin-bottom: 20px;
  }

}

</style>
