import Vue from 'vue'
import Vuex from 'vuex'
import persistedstate from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    useInfo: '',
    app_id: '', // 当前聊天页面的appId
    mainapp_id: '', // 主页列表层面appId,
    appChatList: [], // 当前appId聊天会话记录
    nowChatListItem: '', // 当前选中的list 对象
    isDialogQuestion: '', // 弹窗里问题 有则代表用弹窗进入的聊天页面  请求完chatgpt立马清空
    chat_detail_id: '', // 重新生成使用
    loginType: 'wx', // 微信登录 wx 还是 短信登录 message
    appInfo: '', //当前进行的app信息
    isRegister: false, //是否是新注册的  新注册的 要弹窗
    user_sign: '', // 分享链接带过来得  登录传
    questionNumber: '', // 我的风车数量
    mhomeTab: 3,// 移动端首页tab
    inputPromptShow: false,
    searchAppText: '',
    setApplicationInfo: {}
  },
  getters: {
    inputPromptShow(state) {
      return state.inputPromptShow
    },
    appInfo(state){
      return state.appInfo
    },
    searchAppText(state) {
      return state.searchAppText
    }
  },
  mutations: {
    //设置是否是新注册的
    setUserSign(state, data) {
      state.user_sign = data
    },
    //设置是否是新注册的
    setRegister(state, data) {
      state.isRegister = data
    },
    //设置登录类型
    setAppInfo(state, data) {
      state.appInfo = data
    },
    //设置登录类型
    setLoginType(state, data) {
      state.loginType = data
    },
    //设置登录类型
    setQuestionNumber(state, data) {
      state.questionNumber = data
    },
    //设置全局存用户信息
    setUseInfo(state, data) {
      state.useInfo = data
    },
    setUseInfoIsBindStatus (state, bindStatus) { // 改变id
      state.useInfo.is_bind_status = bindStatus
    },
    setUseInfoIsYearVip (state, bindStatus) { // 是否是vip
      if(state.useInfo){
       state.useInfo.is_year_vip = bindStatus
      }
    },
    setUseInfoVipType (state, type) { // 是否是vip
      state.useInfo.vip_type = type
    },
    //设置当前会话的聊天列表 --总数据
    setAppChatList(state, data) {
      state.appChatList = data
    },

    //设置当前进入的appId
    setAppId(state, data) {
      state.app_id = data
    },
    //设置当前进入的appId
    setMainAppId(state, data) {
      state.mainapp_id = data
    },

    //chat_detial_ud 重新生成需要传
    setChatDetailId(state, data) {
      state.chat_detail_id = data
    },

    //设置正在进行的会话
    setNowChat(state, data) {
      state.nowChatListItem = data
    },

    //设置弹窗时带的问题  使用完要立刻清空
    setIsDialogQuestion(state, data) {
      state.isDialogQuestion = data
    },
    //一旦畅聊过存储此标识
    setIsAIQuestion(state, data) {
      state.isAIQuestion = data
    },
    //清空当前会话的聊天列表
    clearAppChatList(state) {
      state.appChatList = []
    },
    //清空当前会话的聊天列表
    setMhomeTab(state, tab) {
      state.mhomeTab = tab
    },
    inputPromptShow(state, isShow) {
      state.inputPromptShow = isShow
    },
    setSearchAppText(state, searchAppText) {
      state.searchAppText = searchAppText
    },
    setApplicationInfo(state, data) {
      state.setApplicationInfo = data
    }
  },
  actions: {},
  modules: {},
  plugins: [
    persistedstate(),
  ],
})

