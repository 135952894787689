import { render, staticRenderFns } from "./guideNav.vue?vue&type=template&id=2f5dd7ea&scoped=true"
import script from "./guideNav.vue?vue&type=script&lang=js"
export * from "./guideNav.vue?vue&type=script&lang=js"
import style0 from "./guideNav.vue?vue&type=style&index=0&id=2f5dd7ea&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f5dd7ea",
  null
  
)

export default component.exports