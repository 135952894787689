<template>
  <div class="pup inputPrompt">
    <van-popup
      v-model="diaValue"
      class="pup"
      round
      :close-on-click-overlay="true"
      :closeable="true"
      @click-overlay="close"
      @click-close-icon="close"
      :style="{ height: '580px', width: setwidth }"
      :get-container="getContainer"
      @touchmove.stop.prevent="moveHandle"
    >
      <p class="title">
        {{ chooseItem && chooseItem.app_title }}
      </p>
      <div class="line"></div>
      <div class="prompt_wrap">
        <div class="tips">
          <!-- <img :src="require('@/assets/img/zhi_ling.png')" /> -->
          指令逻辑:
        </div>
        <MDPreview
          class="intro mt5 mb5"
          :plainText="chooseItem && chooseItem.app_description"
        ></MDPreview>
        <div class="plase">
          <!-- <img :src="require('@/assets/img/shu_ru.png')" /> -->
          请输入您的指令:
        </div>
        <div class="textarea_box">
          <textarea
            ref="textarea"
            v-model="question"
            maxlength="800"
            :focus="true"
            @input="change"
            @blur="blur"
            class="textarea_hander"
          ></textarea>
          <div
            class="inputpre"
            v-html="placeolderText"
            v-if="showPlaceolder"
          ></div>
          <div class="inputpre" v-else></div>
          <span class="numTotal">
            <span class="Cblue">
              {{ question.length }}</span>/800
              |
            <span class="btn" @click="clearQuestion">清空</span>
          </span>
          <span class="fengche_tips">消耗小风车 1个/次</span>
          <span class="fengche_tips fengche_tips1">内容由AI生成，不能完全保障真实</span>
        </div>
        <div class="footer">
          <div class="btns" @click="save">确定</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { debounce } from '@/util/common'
import MDPreview from '@/components/MDPreview.vue'

export default {
  components: { MDPreview },
  props: {
    chooseItem: {
      type: Object,
      default: () => {},
    },
    value: {
      default: false,
    },
  },
  data() {
    return {
      question: '',
      showPlaceolder: true,
      diaValue: true
    }
  },
  computed: {
    placeolderText() {
      if (this.chooseItem) {
        var pattern3 = /\（|\(/g // 匹配英文的括号
        var pattern4 = /\）|\)/g // 匹配英文的括号
        let str = this.chooseItem.app_command_params
        let newstr = str
          .replace(pattern3, ' <span>( ')
          .replace(pattern4, ' )</span> ')

        newstr = '<span>指令示例：</span>' + newstr
        return newstr
      } else {
        return ''
      }
    },
    // 不支持响应式  得刷新页面生效
    setwidth() {
      if (document.body.offsetWidth >= 992) {
        return '750px'
      } else {
        return '330px'
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.textarea.focus()
    })
  },
  methods: {
    moveHandle () {},
    getContainer () {
      return document.body
    },
    clearQuestion () {
      this.question = ''
    },
    change(v) {
      if (v) {
        this.showPlaceolder = false
      } else {
        this.showPlaceolder = true
      }
    },

    blur() {
      if (!this.question) {
        this.showPlaceolder = true
      } else {
        this.showPlaceolder = false
      }
    },
    save: debounce(function () {
      if (!this.question) {
        this.$message({
          message: '请输入您的指令',
          type: 'warning',
        })
        return
      }
      // 存从弹窗进入标识
      this.$store.commit('setIsDialogQuestion', this.question)

      this.$emit('input', false)
      this.close()
      this.$router.push({ path: '/chat' })
    }),
    close() {
      this.$store.commit('inputPromptShow', false)
    },
  },
}
</script>

<style lang="less" scoped>
.pup {
  position: fixed;
  // transform: translate3d(0, 0, 0);
  .title {
    width: 92%;
    text-align: center;
    color: #3d3d3d;
    font-weight: bold;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 20px;
    background-color: #fff;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #d8d8d8;
  }
  /deep/ .van-popup {
    background-color: #fff;
  }
  .prompt_wrap {
    width: 100%;
    height: 240px;
    box-sizing: border-box;
    margin-top: 50px;
    padding: 15px;
    box-sizing: border-box;
    position: relative;
    color: #ffffff;
    font-size: 14px;
    /deep/ .markdown-body{
      line-height: 24px;
      color: #3d3d3d;
    }
    .btns {
      cursor: pointer;
      max-width: 332px;
      height: 40px;
      background: linear-gradient(135deg, #416fff 0%, #00ced1 100%);
      border-radius: 12px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      margin-top: 25px;
      margin-bottom: 10px;
    }
    .highlight {
      color: #ffffff !important;
    }
    .textarea_box {
      box-sizing: border-box;
      width: 100%;
      z-index: 2;
      height: 160px;
      border-radius: 8px;
      position: relative;
      line-height: 24px;
      .numTotal {
        position: absolute;
        right: 10px;
        bottom: -26px;
        z-index: 2;
        color: #999;
        font-size: 10px;
        .Cblue{
          color: #0658F9;
        }
        .btn{
          cursor: pointer;
        }
      }
      .fengche_tips{
        font-size: 10px;
        position: absolute;
        right: 10px;
        bottom: -45px;
        z-index: 2;
        color: #999;
      }
      .fengche_tips1{
        bottom: -65px;
      }
      .inputpre {
        background-color: #fff;
        width: 100%;
        height: 160px;
        overflow: auto;
        color: #909090;
        box-sizing: border-box;
        padding: 10px;
        /deep/ span {
          color: #3d3d3d !important;
        }
      }
      .textarea_hander {
        width: 100%;
        position: absolute;
        z-index: 2;
        height: 160px;
        left: 0px;
        top: 0px;
        border-radius: 8px;
        color: #3d3d3d;
        box-sizing: border-box;
        padding: 10px;
        background: transparent;
        outline: none;
        border: 1px solid #cecece;
      }
    }
    .footer{
      position: relative;
      .tips{
        position: absolute;
        left: 0;
        font-size: 12px;
        color: #989898;
        font-weight: normal;
      }
    }

    .intro {
      width: 100%;
      height: 170px;
      overflow-y: auto;
      box-sizing: border-box;
      padding: 5px;
      text-align: left;
      white-space: pre-wrap;
      word-wrap: break-word;
      background-color: #fff !important;
      /deep/ p,
      span {
        font-size: 14px !important;
        color: #3d3d3d;
      }
    }
    > img {
      width: 100%;
    }
    .tips {
      color: #3d3d3d;
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;
      background-color: #fff !important;
      margin-bottom: 12px;
      margin-top: 12px;

      img {
        width: 16px;
        margin-right: 4px;
      }
    }
    .plase {
      color: #3d3d3d;
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;
      margin-top: 16px;
      margin-bottom: 16px;
      img {
        width: 16px;
        margin-right: 4px;
      }
    }
  }
  // pc
  @media screen and (min-width: 993px) {
    .title {
      font-size: 20px;
    }
    .btns {
      margin-top: 20px !important;
    }
  }

  // 手机
  @media screen and (min-width: 0px) and (max-width: 992px) {
    .title {
      font-size: 20px;
    }
  }
}
.inputPrompt{
  /deep/ .markdown-body{
    line-height: 24px;
    color: #000!important;
  }
}
</style>

