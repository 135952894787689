import Vue from 'vue'
import axios from 'axios'
import config from '@/config'
import { Message } from 'element-ui'
import store from '../store'
import { reLaunchApp } from '@/util/wx'
import { ENV } from '@/util/common'
import { Toast } from 'vant'
import { isPc } from '@/util/common'
// import { app } from '@/main'

console.log(app, 'appappapp')

let doctorBase = axios.create({
  baseURL: config.host,
  // withCredentials: true,
})

// 添加请求拦截器
doctorBase.interceptors.request.use(
  function (config) {
    if (!config.data) {
      config.data = {}
    }
    let headers = config.headers['ContentType']
    config.headers['Content-Type'] = headers || 'multipart/form-data'
    // config.headers['member-token'] = store.state.useInfo.member_token || ''

    if(config.headers['responseType']){
      config.responseType = config.headers['responseType'];
    }
    // 发送请求前
    return config
  },
  function (err) {
    // 请求错误
    return Promise.reject(err)
  }
)
// 添加响应拦截器
doctorBase.interceptors.response.use(
  function (rs) {
    // console.log(rs, '进来啦')
    if(rs.data instanceof Blob){
      // console.log(rs.data, 'BlobBlob')
      if(rs.data.type == 'application/json'){
          const reader = new FileReader()
          // 读取文件并在加载完成后显示预览
          reader.onload = function (e) {
            // console.log('e.target.result', JSON.parse(e.target.result))
            // {code: 401, msg: '登录失效，请重新登录'}
            let resultObj = JSON.parse(e.target.result)
            console.log(resultObj,'resultObj')
            if (resultObj.code === 402) {
              Vue.prototype.$bus.$emit('listenChargeTips', true)
            }
            if (resultObj.code === 401 ||
              ['登录失效，请重新登录', 'memberToken不存在'].includes(resultObj.msg)){
                // 清理缓存
                localStorage.removeItem('USERINFO')
                if (!isPc()){
                  if (Vue.prototype.$env === ENV[1].plat){
                    reLaunchApp({ url: `/pages/index/index` })
                  } else {
                    // 本地pc调试移动端时候使用
                    return window.open(`${window.location.origin}/#/login`, '_self')
                  }
                } else {
                  Message.error({
                    showClose: true,
                    message: '请先登录',
                    duration: 2000,
                  })
                  return window.open(`${window.location.origin}/#/login`, '_self')
                }
            } else {
              Message.error({
                showClose: true,
                message: JSON.parse(e.target.result).msg,
                duration: 2000,
              })
              return {}
            }
          }
          reader.readAsText(rs.data)
          return
        } else{
          return URL.createObjectURL(rs.data)
        }
    }
    // 小风车不足时，弹框提示
    if (rs.data.code === 402) {
      console.log('发送通知')
      Vue.prototype.$bus.$emit('listenChargeTips', true)
    }

    if (
      rs.data.code === 401 ||
      ['登录失效，请重新登录', 'memberToken不存在'].includes(rs.data.msg)
    ) {
      // 清理缓存
      localStorage.removeItem('USERINFO')
      if (!isPc()){
        if (Vue.prototype.$env === ENV[1].plat){
          reLaunchApp({ url: `/pages/index/index` })
        } else {
          // 本地pc调试移动端时候使用
          return window.open(`${window.location.origin}/#/login`, '_self')
        }
      } else {
        Message.error({
          showClose: true,
          message: '请先登录',
          duration: 2000,
        })
        return window.open(`${window.location.origin}/#/login`, '_self')
      }
    }
    // if (rs.data.code === 400) {
    //   Message.error({
    //     showClose: true,
    //     message: rs.data.msg,
    //     duration: 2000,
    //   })
    // }
    // console.log(rs.headers.get('content-type'), 'Content-Type')
    let code = rs.data.code || rs.code || rs.status
    if (rs && rs.data && (code !== 200 && code !== 997 && code !== 998)) {
      Message.error({
        showClose: true,
        message: rs.data.msg,
        duration: 2000,
      })
    }
    // console.log(rs, 'axios response')
    // 响应数据
    return rs.data
  },
  function (err) {
    if (err) {
      console.log("err",err)
    }

    return Promise.reject(err)
  }
)

/**
 * 取消同一个接口之前未回调的请求
 * @params.url        接口地址
 * @params.params     接口请求参数
 */
let axiosCancel = {}
doctorBase.cancelPost = async (url, params) => {
  try {
    let key = new Date().getTime()
    const res = await doctorBase.post(url, params, {
      cancelToken: new doctorBase.CancelToken(function executor(c) {
        if (!axiosCancel[url]) {
          axiosCancel[url] = {}
        }
        axiosCancel[url][key] = c
      }),
    })
    if (axiosCancel[url]) {
      for (let item in axiosCancel[url]) {
        // 取消本次请求之前未回调的请求 阻断其他未返回结果的接口
        if (axiosCancel[url][item] && Number(item) < key) {
          axiosCancel[url][item]()
          delete axiosCancel[url][item]
        }
      }
      delete axiosCancel[url][key]
    }
    return res
  } catch (err) {
    return null
  }
}
export { doctorBase }

